import React, { Suspense, useEffect, useState } from "react";
import Footer from "../../Components/Footer/footer";
import { useNavigate } from "react-router";
import performRequest from "../../APIs/request";
import { useSelector } from "react-redux";
import { FcApproval, FcCallback, FcAddDatabase, FcRules } from "react-icons/fc";
import { FiGitPullRequest } from "react-icons/fi";
import { Toast } from "../CustomComponent/toast";
import { CustomErrorResponse } from "../CustomComponent/custom_error_response";
import { recruiter, superAdmin } from "../../Config/config";
import Attendancetable from "../Internals/Employee Attandance/attendence_table";
import Attendancetracking from "./Dashboard Components/attendance_tracking";

const tableData = "df";
const Recruiterdashboard = () => {
  const store = useSelector((store) => store?.userLogin);
  const navigate = useNavigate();
  const [taskData, setTaskData] = useState();
  const [analyticsData, setAnalyticsData] =useState({})
  const [loading, setLoading] = useState(false);
  const [employeeName, setEmployeeName] = useState(
    store && store?.user?.Role === superAdmin ? "All" : store?.user?.Name
  );
 
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [attendanceData, setAttendanceData] = useState();
  const years = [2024, 2025, 2026, 2027, 2028, 2029, 2030];
  const months = [
    { name: "January", value: 0 },
    { name: "February", value: 1 },
    { name: "March", value: 2 },
    { name: "April", value: 3 },
    { name: "May", value: 4 },
    { name: "June", value: 5 },
    { name: "July", value: 6 },
    { name: "August", value: 7 },
    { name: "September", value: 8 },
    { name: "October", value: 9 },
    { name: "November", value: 10 },
    { name: "December", value: 11 },
  ];

  const ClassNameReturnAttendance = () => {
    let className = "col-md-12 col-lg-12";
    if (store.Role == superAdmin) {
      className = "col-md-12 col-lg-8";
    }
    return className;
  };


  useEffect(() => {
    if (store?.user?.Role === superAdmin || store?.user?.Role === recruiter) {
      performRequest("GET", `/alltabledata/getdashboardanalytics/`)
        .then((res) => {
          if (res.status === 200) {
            setAnalyticsData(res.data)

          } else {
            Toast("error", "Error while getting Tasks!");
          }
        })
        .catch((err) => {
          CustomErrorResponse(err);
        })
        .finally(() => setLoading(false));
    }
  },[])



  useEffect(() => {
    if (store?.user?.isEmployee === true) {
      performRequest("GET", `/tasks/gettaskmanagement/`)
        .then((res) => {
          if (res.status === 200) {
            const currentDate = new Date();
            const filteredData = res.data.taskManagementData
              .filter((task) => task.assignTo === store?.user?.UserId)
              .filter((task) => {
                const startDate = new Date(task.startDate);
                const frequency = parseInt(task.frequency, 10);
                const frequencyType = task.frequencyType;

                if (task.taskType === "one time") {
                  if (task.status === "completed") {
                    const completedTime = new Date(task.completedTime);
                    return completedTime >= currentDate;
                  } else {
                    return true;
                  }
                } else if (task.taskType === "repeat task") {
                  if (startDate > currentDate) {
                    return false;
                  }

                  let nextOccurrence = new Date(startDate);
                  while (nextOccurrence <= currentDate) {
                    if (
                      nextOccurrence.toDateString() ===
                      currentDate.toDateString()
                    ) {
                      return true;
                    }
                    switch (frequencyType) {
                      case "days":
                        nextOccurrence.setDate(
                          nextOccurrence.getDate() + frequency
                        );
                        break;
                      case "months":
                        nextOccurrence.setMonth(
                          nextOccurrence.getMonth() + frequency
                        );
                        break;
                      case "years":
                        nextOccurrence.setFullYear(
                          nextOccurrence.getFullYear() + frequency
                        );
                        break;
                      default:
                        return false;
                    }
                  }
                  return false;
                }

                return false;
              });

            setTaskData(filteredData);
          } else {
            Toast("error", "Error while getting Tasks!");
          }
        })
        .catch((err) => {
          CustomErrorResponse(err);
        })
        .finally(() => setLoading(false));
    }
  }, [store?.user?.UserId]);
  useEffect(() => {
    if (employeeName && store?.user?.isEmployee === true) {
      getAttendanceByEmployeeName(employeeName);
      // getEmployeeSalaryByEmployeeName(employeeName);
    }
  }, [employeeName]);

  const goToViewTasks = (id) => {
    navigate(`/task-management/view/${id}`);
  };

  function getAttendanceByEmployeeName(employeeName) {
    if (employeeName == "All") {
      performRequest("GET", `/attendance/getallattendance`)
        .then((res) => {
          if (res.status === 200) {
            setAttendanceData(res.data);
          } else {
            Toast("error", "Error while getting Attendance!");
          }
        })
        .catch((err) => {
          CustomErrorResponse(err);
        })
        .finally(() => setLoading(false));
    } else {
      performRequest(
        "GET",
        `/attendance/getallattendance?id=${store?.user?.UserId}`
      )
        .then((res) => {
          if (res.status === 200) {
            setAttendanceData(res.data);
          } else {
            Toast("error", "Error while getting Attendance!");
          }
        })
        .catch((err) => {
          CustomErrorResponse(err);
        })
        .finally(() => setLoading(false));
    }
  }
  return (
    <div className="Page">
      <div className="page-wrapper">
        <div className="page-body">
          <div className="container-xxl">
            <div className="row row-deck row-cards my-3">
              <>
                <div className="col-sm-6 col-lg-3">
                  <div className="card bg-primary-lt card-sm">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-3">
                          <span className="">
                            {/* <IoDocumentTextOutline /> */}
                            <FcAddDatabase className="fs-1" />
                          </span>
                        </div>
                        <div className="col">
                          <div className="fw-bold">
                            {(analyticsData && analyticsData?.totalCompanyRegistered) || 0}
                            {"  "} Company Registered
                          </div>
                          <div className="text-muted">
                            {(analyticsData && analyticsData?.todayCompanyRegistered) || 0}{" "}
                            today
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                  <div className="card bg-primary-lt card-sm">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-3">
                          <span className="">
                            {/* <IoCallOutline /> */}

                            <FcAddDatabase className="fs-1" />
                          </span>
                        </div>
                        <div className="col">
                          <div className="fw-bold">
                            {" "}
                            {(analyticsData && analyticsData?.totalJobsCreated) || 0}
                            {"  "}
                            Jobs Created
                          </div>
                          <div className="text-muted">
                            {" "}
                            {(tableData && tableData?.enquiryListToday) ||
                              0}{" "}
                            today
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                  <div className="card bg-primary-lt card-sm">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-3">
                          <span className="">
                            {/* <IoCallOutline /> */}

                            <FiGitPullRequest
                              className="fs-1"
                              style={{ color: "green" }}
                            />
                          </span>
                        </div>
                        <div className="col">
                          <div className="fw-bold">
                            {" "}
                            {(analyticsData && analyticsData?.totalOnboardingRequest) || 0}
                            {"  "}
                            Onboarding Request
                          </div>
                          <div className="text-muted">
                            {" "}
                            {(analyticsData && analyticsData?.enquiryListToday) ||
                              0}{" "}
                            today
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                  <div className="card bg-primary-lt card-sm">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-3">
                          <span className="">
                            {/* <MdOutlineGroup /> */}
                            <FcApproval className="fs-1" />
                          </span>
                        </div>
                        <div className="col">
                          <div className="fw-bold">
                            {" "}
                            {(analyticsData && analyticsData?.totalCandidtateRegistered) || 0}
                            {"  "}
                            Candidate Registered
                          </div>
                          <div className="text-muted">
                            {" "}
                            {(analyticsData && analyticsData?.todayCandidateRegistered) || 0} today
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>

              {taskData?.length > 0 && (
                <div className="col-12">
                  <div className="card card-sm">
                    <div className="card-header">
                      <h3 className="card-title">Today's Tasks</h3>
                    </div>
                    <div
                      className="card-table table-responsive"
                      style={{ maxHeight: "470px" }}
                    >
                      <table className="table table-vcenter">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Deadline</th>
                            <th>Task Type</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {taskData?.map((task) => (
                            <tr key={task.id}>
                              <td>{task?.name}</td>
                              <td>
                                {task?.taskType === "one time" ? (
                                  task?.status === "pending" ? (
                                    <span
                                      className="badge bg-orange"
                                      style={{ color: "white" }}
                                    >
                                      Pending
                                    </span>
                                  ) : (
                                    <span
                                      className="badge bg-green"
                                      style={{ color: "white" }}
                                    >
                                      Completed
                                    </span>
                                  )
                                ) : (
                                  <span
                                    className="badge bg-orange"
                                    style={{ color: "white" }}
                                  >
                                    Pending
                                  </span>
                                )}
                              </td>
                              <td>{task?.deadline || "N/A"}</td>
                              <td>{task?.taskType || "N/A"}</td>
                              <td>
                                <button
                                  className="btn btn-primary"
                                  style={{
                                    width: "50px",
                                    height: "25px",
                                  }}
                                  onClick={() => goToViewTasks(task?.id)}
                                >
                                  View
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}

              {store?.user?.isEmployee === true && (
                <div className={ClassNameReturnAttendance()}>
                  <div className="card border">
                    <div className="card-header">
                      <h3 className="card-title">Attendance summary</h3>
                      <div className="ms-auto">
                        <div className="d-flex">
                          {store?.user?.Role === superAdmin && (
                            <div className="me-2">
                              <div className="dropdown">
                                <a
                                  className="dropdown-toggle text-muted"
                                  href="/"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  {employeeName}
                                </a>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <span
                                    onClick={() => setEmployeeName("All")}
                                    className="dropdown-item"
                                  >
                                    All
                                  </span>
                                  {/* {leadOwner &&
                                            leadOwner.map((data, index) => (
                                              <span
                                                key={index}
                                                onClick={() =>
                                                  setEmployeeName(data.userName)
                                                }
                                                className="dropdown-item"
                                              >
                                                {data?.userName}
                                              </span>
                                            ))} */}
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="dropdown">
                            <a
                              className="dropdown-toggle text-muted"
                              href="/"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              {months[selectedMonth].name}
                            </a>
                            <div className="dropdown-menu dropdown-menu-end">
                              {months.map((month) => (
                                <span
                                  key={month.value}
                                  onClick={() => setSelectedMonth(month.value)}
                                  className="dropdown-item"
                                >
                                  {month.name}
                                </span>
                              ))}
                            </div>
                          </div>
                          <div className="dropdown ms-2 me-2">
                            <a
                              className="dropdown-toggle text-muted"
                              href="/"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              {selectedYear}
                            </a>
                            <div className="dropdown-menu dropdown-menu-end">
                              {years.map((year) => (
                                <span
                                  key={year}
                                  onClick={() => setSelectedYear(year)}
                                  className="dropdown-item"
                                >
                                  {year}
                                </span>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {attendanceData && (
                      // <Suspense fallback={<LoadingPage />}>
                      <Attendancetracking
                        attendanceData={attendanceData?.Attendance}
                        attendanceFrom={selectedMonth}
                        selectedYear={selectedYear}
                        // employeeSalary={employeeSalaryData}
                        employeeName={employeeName}
                      />
                      // {/* </Suspense> */}
                    )}
                  </div>
                </div>
              )}
              <div className="col-lg-6 col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">
                      <FcRules className="fs-2 me-1" /> Attendance Policy
                    </h3>
                  </div>
                  <div className="card-body">
                    <ul>
                      <li className="m-1">
                        Your working hours start at 09:00AM and finish at
                        06:00PM.
                      </li>
                      <li className="m-1">
                        You have a lunch break from 01:30PM to 02:30PM.
                      </li>
                      <li className="m-1">
                        You are expected to login at 09:00AM. In some
                        situations, you can redeem a grace period of 10 minutes.
                      </li>
                      <li className="m-1">
                        Any login after 09:10AM will be considered as ‘Late
                        Coming’.
                      </li>
                      <li className="m-1">
                        On 3 Late Comings, one day salary will be deducted.
                      </li>
                      <li className="m-1">
                        You are expected to contribute 09 hours to work every
                        day.
                      </li>
                      <li className="m-1">
                        Logging off before 09 hours will be considered as ‘Half
                        Day’.
                      </li>
                      <li className="m-1">
                        If you do not log out, it will be considered as a 'Half
                        Day'.
                      </li>
                      <li className="m-1">
                        All salary calculations are automated based on the above
                        rules.
                      </li>

                      <li>
                        Not adhering to above attendance rules may result in
                        warning for disciplinary action.
                      </li>
                      <li className="m-1">
                        KBPL is an equal opportunity and unbiased organization.
                        The rules are applicable to everyone unless otherwise
                        stated in writing by management.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">
                      <FcRules className="fs-2 me-1" /> Leave Policy
                    </h3>
                  </div>
                  <div className="card-body">
                    <ul>
                      <li className="m-1">
                        When you are in probation or in notice period; all
                        leaves will be unpaid.
                      </li>
                      <li className="m-1">
                        All confirmed employees are entitled to a total of 15
                        leaves per calendar year devided in quarters as
                        Q1:3leaves, Q2:4leaves, Q3:4leaves, Q4:4leaves.
                      </li>
                      {/* <li className="m-1">
                           Leave allocation is structured on a quarterly
                           basis as
                           <ul>
                             <li>1st Quarter :- 3 leaves</li>
                             <li>2nd Quarter :- 4 leaves</li>
                             <li>3rd Quarter :- 4 leaves</li>
                             <li>4th Quarter :- 4 leaves</li>
                           </ul>
                         </li> */}
                      <li className="m-1">
                        You must submit a leave application at least 3 days in
                        advance for planned leaves using leave management
                        system.
                      </li>
                      <li className="m-1">
                        Not utilising the leave management system will result in
                        deduction of additional half day of pay for total number
                        of leaves taken.
                      </li>
                      <li className="m-1">
                        Approval of leaves is subject to the discretion of the
                        higher authority and the department needs.
                      </li>
                      <li className="m-1">
                        You can choose to work on additional days in any
                        calendar year. And get comp-off against it.
                      </li>
                      <li className="m-1">
                        Comp off leaves are mandatory to apply in leave
                        management system.
                      </li>
                      <li className="m-1">
                        In a month you can not avail more then 3 comp-off
                        leaves.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recruiterdashboard;
