import React, { useEffect } from 'react';

const MetaTags = ({ title, description, image, url }) => {
  useEffect(() => {
    document.title = title;
    document.querySelector('meta[name="description"]').setAttribute("content", description);
   
    // Set Open Graph meta tags
    const metaTags = [
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:image', content: image },
      { property: 'og:url', content: url },
    ];

    metaTags.forEach(({ property, content }) => {
      let tag = document.querySelector(`meta[property="${property}"]`);
      if (!tag) {
        tag = document.createElement('meta');
        tag.setAttribute('property', property);
        document.head.appendChild(tag);
      }
      tag.setAttribute('content', content);
    });

    // Clean up meta tags on unmount
    return () => {
      metaTags.forEach(({ property }) => {
        const tag = document.querySelector(`meta[property="${property}"]`);
        if (tag) {
          document.head.removeChild(tag);
        }
      });
    };
  }, [title, description, image, url]);

  return null;
};

export default MetaTags;
