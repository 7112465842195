import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import performRequest from "../../APIs/request";
import { Toast } from "../CustomComponent/toast";
import LoadingPage from "../../Components/layouts/loading";
import { Formik, Form } from "formik";
import { editProfileInfo } from "../../Schema Info/editprofileinfo";
import { editProfileSchema } from "../../Schemas/User/edit_profile_schema";
import CustomInput from "../CustomComponent/custom_input";
import { editCompanyProfileSchema } from "../../Schemas/User/edit_companyprofile_schema";
import CustomSelect from "../CustomComponent/custom_select";
import { editCompanyProfileInfo } from "../../Schema Info/edit_companyprofileInfo";
import { login } from "../../Redux/User/login_reducer";
import { useDispatch } from "react-redux";
import { company } from "../../Config/config";
import CustomTextArea from "../CustomComponent/custom_textarea";
import { changePasswordSchema } from "../../Schemas/User/change_password_schema";
import { instamojoBulkSchema } from "../../Schemas/instamojo_schema";
import Buttonloader from "../../Components/layouts/button_loader";
import { extractDate } from "../../Components/Functions/date.fun";
import Footer from "../../Components/Footer/footer";
import { notification } from "../../Redux/notifications/notificationsReducer";
import { getNotifications } from "../../Components/layouts/getnotifications";
import { addAdminCreditSchema } from "../../Schemas/credit_admin_schema";
import { useNavigate } from "react-router";
import StepRoutes from "../../Routes/steps_routes";
import FormSubmitButton from "../../Components/layouts/formSubmitButton";
import SimpleButton from "../../Components/layouts/simple_button";
import { userDetails } from "../../Schema Info/info";
import { accountSettingSchema } from "../../Schemas/User/account_setting_schema";
import { CustomErrorResponse } from "../CustomComponent/custom_error_response";

function Myprofile() {
  const store = useSelector((store) => store?.userLogin);
  const [data, setData] = useState(editProfileInfo);
  const [edata, setEdata] = useState(editCompanyProfileInfo);
  const [inputImage, setInputImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [profilePicture, setProfilePicture] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [payButton, setPayButton] = useState(false);
  const [token, setToken] = useState(null);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [timer, setTimer] = useState(null);
  const [inputLoader, setInputLoader] = useState(false)
  const [userInfo, setUserInfo] = useState(userDetails);
  const dispatch = useDispatch();
  const navigate =useNavigate()
  const imageRef = useRef(null);
  const [refresh, setRefresh] = useState(false);
  


  useEffect(() => {
    // Dynamic code

    const getMyprofile = () => {
      performRequest("GET", `/user/userbyid/${store?.user.UserId}`)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.userRole === 'company') {
              setEdata(res.data.employeronboardingmodels[0]);
              setProfilePicture(
                res.data.employeronboardingmodels[0].companyLogo
              );
            } else {
              setData(res.data);
              setProfilePicture(res.data.profilePicture);
            }
          } else {
            Toast("error", "Server Error");
          }
        })
        .catch((err) => {
          console.error("Error", err);
        })
        .finally(() => setLoading(false));
    };

    getMyprofile();
  }, []);

  useEffect(() => {
    const getAllTransactions = () => {
      performRequest(
        "GET",
        `/payment/getalltransactinbyid/${store?.user?.UserId}`
      ).then((res) => {
        if (res.status === 200) {
          setTransactions(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    };

    if (store?.user?.Role === "company") {
      getAllTransactions();
    }
  }, []);


  useEffect(() => {

    const getMyprofile = () => {
      performRequest("GET", `/employeemanagement/getemployeemanagementbyid/${store?.user.UserId}`)
        .then((res) => {
          if (res.status === 200) {
          
              setUserInfo(res.data);
              setProfilePicture(res.data.photograph);
          }
        })
        .catch((err) => {
          console.error("Error", err);
        })
        .finally(() => setLoading(false));
    };

    if(store?.user.isEmployee === true){
      getMyprofile();
    }
  }, [store?.user?.UserId, refresh]);

  const handleEditImage = (e) => {
    e.preventDefault();
    imageRef.current.click();
  };

  const handleEditProfile = (values) => {
    const formData = new FormData();
    formData.append("profilePicture", values.profilePicture);
    formData.append("email", values.email);
    formData.append("contactNumber", values.contactNumber);
    formData.append("fullName", values.fullName);

    performRequest(
      "PATCH",
      `/user/updateuserbyuserid/${store?.user?.UserId}`,
      formData,
      {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    )
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            login({ ...store.user, profilePicture: res.data.profilePicture })
          );
          Toast("success", "Profile Update Successfully");
        } else {
          Toast("error", "Server Error");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file); // Create a preview URL
      setInputImage(imageUrl); // Update the state with the new image
    }
  };

  const handleEditEmployerProfile = (values) => {
    const formData = new FormData();
    for (let key in values) {
      formData.append(key, values[key]);
    }

    performRequest(
      "PATCH",
      `/employer/update-onboarded-employee/${store?.user?.UserId}`,
      formData,
      {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    )
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            login({ ...store.user, profilePicture: res.data.companyLogo })
          );
          Toast("success", "Profile Update Successfully");
        } else {
          Toast("error", "Server Error");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      });
  };

  const handelSubmit = async (values,Actions) => {
    const data = { password: values.password, oldPassword: values.oldPassword };

    try {
      const res = await performRequest(
        "PATCH",
        `/auth/changepassword/${store?.user?.UserId}`,
        data
      );

      if (res.status === 200) {
        Toast("success", "Password changed successfully");
        let userId = store?.user?.UserId;

        if (userId) {
          const notificationData = await getNotifications(userId);

          // Do something with the notifications
          dispatch(notification(notificationData));
        }
        Actions.resetForm()

        handleButtonClose();
      } else {
        Toast("error", "Server Error");
      }
    } catch (err) {
      if (err.response?.status === 401) {
        Toast("error", "Wrong Credentials");
      } else {
        Toast("error", "Server Error");
      }
      console.error("Error", err);
    }
  };

  const handleButtonClose = () => {
    const button = document.getElementById("btn-close");
    button.click();
  };

  const handleConfirmPayment = (e) => {
    let isUser = store?.user?.UserId;
    if (!isUser) {
      return Toast("error", "Please Login");
    }
    setButtonLoader(true);
    let data = { userId: store?.user.UserId };
    performRequest("POST", "/payment/generatetoken", data)
      .then((res) => {
        if (res.status === 201) {
          setToken(res.data);
          setButtonLoader(false);
          setPayButton(true);
        }
      })
      .catch((err) => {
        console.error("Error", err);
        if (err.response.status === 401) {
          Toast("error", err.response.data.flag);
        } else if (err.response.status === 400) {
          Toast("error", err.response.data.flag);
        } else {
          Toast("error", err.response.statusText);
        }
      });
  };
  const handleFinalPayment = (values) => {
    let isUser = store?.user?.UserId;
    if (!isUser) {
      return Toast("error", "Please Login");
    }

    let data = {
      ...values,
      price: parseInt(values.tier),
      userId: store?.user.UserId,
      token: token,
    };
    setButtonLoader(true);
    performRequest("POST", "/payment/create-payment", data)
      .then((res) => {
        if (res.status === 201) {
          setButtonLoader(false);
          window.location.href = res.data.longurl;
        }
      })
      .catch((err) => {
        console.error("Error", err);
        if (err.response.status === 401) {
          Toast("error", err.response.data.flag);
        } else if (err.response.status === 400) {
          Toast("error", err.response.data.flag);
            setButtonLoader(false)
        
        } else if (err.response.status === 400) {
          Toast("error", err.response.data.flag);
          setButtonLoader(false)
        } else {
          Toast("error", err.response.statusText);
          setButtonLoader(false)
          
        }
      });
  };

  const handleAddCredit = (values,Actions,props) => {
      
    performRequest("POST", "/credit/addcreditbyadmin", values)
      .then((res) => {
        if (res.status === 201) {
          Toast("success", res.data.flag);
          handleCloseModal();
          Actions.resetForm()
        }
      })
      .catch((err) => {
        console.error("Error", err);
        let errValue = err.response.status;
        if (errValue === 401 || errValue === 400 || errValue === 404) {
          Toast("error", err.response.data.flag);
        } else {
          Toast("error", err.response.statusText);
        }
      });
  };

  const handleCompanyChange = (value, props) => {
    setInputLoader(true)
    performRequest("GET", `/employer/getEmployerById/${value}`)
      .then((res) => {
        if (res.status === 200) {
          setInputLoader(false)
          props.setFieldValue("companyName", res?.data?.companyName);
          props.setFieldValue('availableCredits', res?.data?.creditsystemmodels?.[0].credit)
  
        }
      })
      .catch((err) => {
        console.error("Error", err);
        let errValue = err.response.status;
        setInputLoader(false)
        if (errValue === 401 || errValue === 400 || errValue === 404) {
          Toast("error", err.response.data.flag);
        } else {
          Toast("error", err.response.statusText);
        }
      });
  };

  const handleCloseModal = () => {
    let btn = document.getElementById("btn-close-credit");
    let btn2 = document.getElementById('btn-close-qr')
    btn2.click()
    btn.click();
  };

  const handelGenerateQr  =async (values)=>{

  const data = values.qrUrl; // Default text if input is empty
  const size = '250x250'; // Size of the QR code
  const url = `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(data)}&size=${size}`;

  try {
      const response = await fetch(url);
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      const qrCodeUrl = response.url; // Get the URL of the QR code
      handleCloseModal()
      navigate('/qr', { state: { url: qrCodeUrl, purpose:values.purpose } });

  } catch (error) {
      console.error('Error generating QR code:', error);
  }
     
  }

  const handelGenerateQrForEmployer  =async (id,companyName)=>{
  
    const data = `https://www.hire48.com/company-info/${id}`; // Default text if input is empty
    const size = '250x250'; // Size of the QR code
    const url = `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(data)}&size=${size}`;
  
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const qrCodeUrl = response.url; // Get the URL of the QR code

        navigate('/qr', { state: { url: qrCodeUrl, purpose:`${companyName} company` } });
  
    } catch (error) {
        console.error('Error generating QR code:', error);
    }
       
    }



    const handelEmployeeSubmit = (values, actions) => {
      values.firstName = values.firstName.trim();
      values.lastName = values.lastName.trim();
  
      if (values.firstName && values.lastName) {
        values = {
          ...values,
          fullName: `${values.firstName} ${values.lastName}`,
        };
      } else if (values.firstName) {
        values = { ...values, fullName: values.firstName };
      } else if (values.lastName) {
        values = { ...values, fullName: values.lastName };
      }
  
      // Remove the password from the values object
      const { password, ...updatedValues } = values;
  
      let formData = new FormData();
      for (let key in updatedValues) {
        formData.append(key, updatedValues[key]);
      }
  
      const headers = { "Content-Type": "multipart/form-data" };
  
      setLoading(true);
      performRequest("PATCH", `/employeemanagement/updateemployeemanagement/${store?.user?.UserId}`, formData, headers)
        .then((res) => {
          if (res.status === 201) {
           
            let profilePicture = res.data.photograph
            dispatch(login({...store, ...store.user,  profilePicture}))
            Toast("success", "Details Updated successfully!");

            setRefresh(!refresh);
          } else {
            Toast("error", "Error while Updating!");
          }
        })
        .catch((err) => {
          CustomErrorResponse(err);
        })
        .finally(() => setLoading(false));
    };
  




  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page-wrapper">
      <div className="page-body">
        <div className="container-xl">
          {store?.user?.Role !== 'company' && (store?.user.isEmployee !== true) ? (
            <div className="col-12">
              <Formik
                className="card"
                enableReinitialize
                initialValues={data}
                validationSchema={editProfileSchema}
                onSubmit={handleEditProfile}
              >
                {(props) => (
                  <Form className="card">
                    <React.Fragment>
                      <div className="card-header">
                        <h3 className="card-title">My Profile</h3>
                      </div>
                      <div className="card-body">
                        <div className="mb-3">
                          <div className="row row-cards align-items-center">
                            <div className="col-lg-2 text-center col-md-3">
                              <span className="avatar avatar-2xl">
                                {inputImage ? (
                                  <img src={inputImage} alt="" srcSet="" />
                                ) : (
                                  <img src={profilePicture} alt="" srcSet="" />
                                )}
                              </span>
                              <br />
                              <input
                                type="file"
                                ref={imageRef}
                                style={{ display: "none" }} // Hide the input field
                                onChange={(e) => {
                                  handleImageUpload(e);
                                  props.setFieldValue(
                                    "profilePicture",
                                    e.target.files[0]
                                  );
                                }}
                                accept="image/*" // Limit to image files only
                              />

                              <button
                                onClick={handleEditImage}
                                className="btn btn-secondary mt-lg-2 mb-2 ms-1"
                              >
                                Edit Profile
                              </button>
                              {store?.user?.Role === "superAdmin" && (
                                <button
                                  className="btn btn-primary  mt-lg-2 mt-sm-2 mb-2 mt-md-1 ms-2"
                                  data-bs-toggle="modal"
                                  data-bs-target="#modal-credit"
                                  type="button"
                                >
                                  Add Credit
                                </button>
                              )}
                           
                                {(store?.user?.Role ==='recruiter' || store?.user?.Role ==='superAdmin') && (
                                  <button
                                  className="btn btn-tertiory ms-2  mt-lg-2 mt-sm-2 mt-md-1 me-2"
                                  data-bs-toggle="modal"
                                  data-bs-target="#modal-team-qr"
                                  type="button"
                                >
                                  Generate Qr 
                                </button>
                                )}
                              <button
                                className="btn btn-tertiory ms-2  mt-lg-2 mt-sm-2 mt-md-1 me-2"
                                data-bs-toggle="modal"
                                data-bs-target="#modal-team"
                                type="button"
                              >
                                Set New Password
                              </button>
                            
                            </div>
                            <div className="col-lg-7 col-md-9 col-sm-12">
                              <div className="mb-3 row">
                                <label className="form-label col-3">
                                  Name :
                                </label>
                                <div className="col">
                                  {" "}
                                  <CustomInput
                                    name="fullName"
                                    onChange={(e) => {
                                      props.setFieldValue(
                                        "fullName",
                                        e.target.value
                                      );
                                    }}
                                  />
                                  {props.touched.fullName &&
                                    props.errors.fullName && (
                                      <p className="text-danger">
                                        {props.errors.fullName}
                                      </p>
                                    )}
                                </div>
                              </div>
                              <div className="mb-3 row">
                                <label className="form-label col-3">
                                  Email-Address :
                                </label>
                                <div className="col">
                                  {" "}
                                  <CustomInput
                                    name="email"
                                    onChange={(e) => {
                                      props.setFieldValue(
                                        "email",
                                        e.target.value
                                      );
                                    }}
                                  />
                                  {props.touched.email &&
                                    props.errors.email && (
                                      <p className="text-danger">
                                        {props.errors.email}
                                      </p>
                                    )}
                                </div>
                              </div>
                              <div className="mb-3 row">
                                <label className="form-label col-3">
                                  Contact Number
                                </label>
                                <div className="col">
                                  {" "}
                                  <CustomInput
                                    type="text"
                                    name="contactNumber"
                                    onChange={(e) => {
                                      props.setFieldValue(
                                        "contactNumber",
                                        e.target.value
                                      );
                                    }}
                                  />
                                  {props.touched.contactNumber &&
                                    props.errors.contactNumber && (
                                      <p className="text-danger">
                                        {props.errors.contactNumber}
                                      </p>
                                    )}
                                </div>
                              </div>

                              
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer text-end">
                        <button
                          type="submit"
                          // onClick={() => {
                          //   handleEditProfile(props.values);
                          // }}
                          className="btn btn-primary"
                        >
                          Save
                        </button>
                      </div>
                    </React.Fragment>
                  </Form>
                )}
              </Formik>
            </div>
          ) : (store?.user?.isEmployee===true)? (
            
          <>
            <div className="page-header d-print-none">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title mb-1">User Account</h2>
                <h4 className="text-muted">
                  <StepRoutes />
                </h4>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h2 className="page-title">Account Settings</h2>
            </div>

            <div className="card">
              <div className="row g-0">
                <div className="col-3 d-none d-lg-block border-end">
                  <div className="card-body">
                    <h4 className="subheader">Account settings</h4>
                    <div className="list-group list-group-transparent">
                      <a
                        href="./my-profile"
                        className="list-group-item list-group-item-action d-flex align-items-cactive"
                      >
                        My Profile
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col d-flex flex-column">
                  <Formik
                    initialValues={userInfo}
                    validationSchema={accountSettingSchema}
                    onSubmit={handelEmployeeSubmit}
                    enableReinitialize
                  >
                    {(props) => (
                      
                      <Form>
                        <div className="card-body">
                          <h2 className="mb-4">My Profile</h2>
                          <h3 className="card-title">Profile Details</h3>
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <span
                                className="avatar avatar-xl"
                                style={{
                                  backgroundImage: userInfo?.photograph
                                    ? `url(${userInfo?.photograph})`
                                    : `url(https://i.pinimg.com/474x/0a/a8/58/0aa8581c2cb0aa948d63ce3ddad90c81.jpg)`,
                                }}
                              ></span>
                            </div>
                            <div className="col-auto">
                              <CustomInput
                                name="photograph"
                                type="file"
                                onChange={(e) =>
                                  props.setFieldValue(
                                    "photograph",
                                    e.target.files[0]
                                  )
                                }
                              />
                            </div>
                          </div>

                          <h3 className="card-title mt-4">Profile</h3>
                          <div className="grid g-3 my-2">
                            <div className=" g-col-lg-4 g-col-md-6 g-col-12">
                              <label className="form-label">First name</label>
                              <CustomInput
                                type="text"
                                name="firstName"
                                placeholder="First Name"
                                autoComplete="off"
                              />{" "}
                              {props.touched.firstName &&
                                props.errors.firstName && (
                                  <p className="text-danger">
                                    {props.errors.firstName}
                                  </p>
                                )}
                            </div>
                            <div className=" g-col-lg-4 g-col-md-6 g-col-12">
                              <label className="form-label">Last name</label>
                              <CustomInput
                                type="text"
                                name="lastName"
                                placeholder="Last Name"
                                autoComplete="off"
                              />{" "}
                              {props.touched.lastName &&
                                props.errors.lastName && (
                                  <p className="text-danger">
                                    {props.errors.lastName}
                                  </p>
                                )}
                            </div>
                            <div className=" g-col-lg-4 g-col-md-6 g-col-12">
                              <label className="form-label">
                                Date of birth
                              </label>
                              <CustomInput
                                type="date"
                                name="dateOfBirth"
                                autoComplete="off"
                              />{" "}
                              {props.touched.dateOfBirth &&
                                props.errors.dateOfBirth && (
                                  <p className="text-danger">
                                    {props.errors.dateOfBirth}
                                  </p>
                                )}
                            </div>
                            <div className=" g-col-lg-4 g-col-md-6 g-col-12">
                              <label className="form-label">
                                Permanent address
                              </label>
                              <CustomInput
                                type="text"
                                name="permanentAddress"
                                placeholder="your permanent address"
                                autoComplete="off"
                              />{" "}
                              {props.touched.permanentAddress &&
                                props.errors.permanentAddress && (
                                  <p className="text-danger">
                                    {props.errors.permanentAddress}
                                  </p>
                                )}
                            </div>
                            <div className=" g-col-lg-4 g-col-md-6 g-col-12">
                              <label className="form-label">
                                Residential address
                              </label>
                              <CustomInput
                                type="text"
                                name="residentialAddress"
                                placeholder="residential address"
                                autoComplete="off"
                              />{" "}
                              {props.touched.residentialAddress &&
                                props.errors.residentialAddress && (
                                  <p className="text-danger">
                                    {props.errors.residentialAddress}
                                  </p>
                                )}{" "}
                            </div>{" "}
                            <div className=" g-col-lg-4 g-col-md-6 g-col-12">
                              <label className="form-label">
                                Contact number
                              </label>
                              <CustomInput
                                type="number"
                                placeholder="contact number"
                                name="contactNumber"
                                autoComplete="off"
                              />{" "}
                              {props.touched.contactNumber &&
                                props.errors.contactNumber && (
                                  <p className="text-danger">
                                    {props.errors.contactNumber}
                                  </p>
                                )}
                            </div>
                            <div className="g-col-lg-4 g-col-md-6 g-col-12">
                              <label className="form-label">
                                Emergency contact number
                              </label>
                              <CustomInput
                                type="text"
                                name="emergencyContactNumber"
                                placeholder="emergency contact number "
                                autoComplete="off"
                              />{" "}
                              {props.touched.emergencyContactNumber &&
                                props.errors.emergencyContactNumber && (
                                  <p className="text-danger">
                                    {props.errors.emergencyContactNumber}
                                  </p>
                                )}
                            </div>
                            <div className="g-col-lg-4 g-col-md-6 g-col-12">
                              <label className="form-label">
                                Emergency contact name
                              </label>
                              <CustomInput
                                type="text"
                                name="emergencyContactName"
                                placeholder="Emergency Contact Name"
                                autoComplete="off"
                              />{" "}
                              {props.touched.emergencyContactName &&
                                props.errors.emergencyContactName && (
                                  <p className="text-danger">
                                    {props.errors.emergencyContactName}
                                  </p>
                                )}
                            </div>
                            <div className="g-col-lg-4 g-col-md-6 g-col-12">
                              <label className="form-label">
                                Emergency contact relation
                              </label>
                              <CustomSelect
                                type="text"
                                name="emergencyContactRelation"
                                autoComplete="off"
                              >
                                <option value="">Select Relation</option>
                                <option value="father">Father</option>
                                <option value="mother">Mother</option>
                                <option value="spouse">Spouse</option>
                                <option value="sister">Sister</option>
                                <option value="brother">Brother</option>
                                <option value="friend">Friend</option>
                              </CustomSelect>
                              {props.touched.emergencyContactRelation &&
                                props.errors.emergencyContactRelation && (
                                  <p className="text-danger">
                                    {props.errors.emergencyContactRelation}
                                  </p>
                                )}
                            </div>
                          </div>
                          <div className="my-2">
                            <h3 className="card-title mt-4">Password</h3>
                            <p className="card-subtitle">
                              You can change your password here...
                            </p>
                            <button
                                className="btn btn-tertiory ms-2  mt-lg-2 mt-sm-2 mt-md-1 me-2"
                                data-bs-toggle="modal"
                                data-bs-target="#modal-team"
                                type="button"
                              >
                                Set New Password
                              </button>
                            
                          </div>
                        </div>
                        <div className="card-footer bg-transparent mt-auto">
                          <div className="btn-list justify-content-end">
                            <SimpleButton
                              buttonText={"Cancel"}
                              type="secondary"
                            />

                            <FormSubmitButton buttonText={"Submit"} />
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          </>
      
          ) : (
            <div className="col-12">
              <Formik
                className="card"
                enableReinitialize
                initialValues={edata}
                validationSchema={editCompanyProfileSchema}
                onSubmit={handleEditEmployerProfile}
              >
                {(props) => (
                  <Form className="card">
                    <React.Fragment>
                      <div className="card-header">
                        <h3 className="card-title">My Profile</h3>
                      </div>
                      <div className="card-body">
                        <div className="mb-3">
                          <h3>Employer Personal Details</h3>

                          <div className="row row-cards">
                            {/* coloumn one */}
                            <div className="col-lg-auto  col-md-3 col-sm-6">
                              <span className="avatar avatar-2xl">
                                {inputImage ? (
                                  <img src={inputImage} alt="" srcSet="" />
                                ) : (
                                  <img src={profilePicture} alt="" srcSet="" />
                                )}
                              </span>
                              <br />
                              <input
                                type="file"
                                ref={imageRef}
                                style={{ display: "none" }} // Hide the input field
                                onChange={(e) => {
                                  handleImageUpload(e);
                                  props.setFieldValue(
                                    "companyLogo",
                                    e.target.files[0]
                                  );
                                }}
                                accept="image/*" // Limit to image files only
                              />

                              <button
                                onClick={handleEditImage}
                                className="btn btn-secondary mt-1 ms-1"
                              >
                                Edit Profile
                              </button>
                              <br />
                             <button
                             className="btn btn-tertiory mt-lg-2 mt-sm-2 mt-md-1 me-2"
                             type="button"
                             onClick={()=>{handelGenerateQrForEmployer( edata.id,edata.companyName)}}
                           >
                             Create My QR 
                           </button>
                         
                            </div>
                            {/* coloumn Two */}
                            <div className="col-lg-3 col-md-3 col-sm-12">
                              <div className="mb-3">
                                <label className="form-label">Name</label>
                                <CustomInput
                                  name="fullName"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "fullName",
                                      e.target.value
                                    );
                                  }}
                                />
                                {props.touched.fullName &&
                                  props.errors.fullName && (
                                    <p className="text-danger">
                                      {props.errors.fullName}
                                    </p>
                                  )}
                              </div>

                              <div className="mb-3">
                                <label className="form-label">
                                  Designation
                                </label>
                                <CustomInput
                                  type="text"
                                  name="designation"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "designation",
                                      e.target.value
                                    );
                                  }}
                                />
                                {props.touched.designation &&
                                  props.errors.designation && (
                                    <p className="text-danger">
                                      {props.errors.designation}
                                    </p>
                                  )}
                              </div>
                            </div>
                            {/* coloumn three */}
                            <div className="col-lg-3 col-md-3">
                              <div className="mb-3">
                                <label className="form-label">
                                  Email-Address
                                </label>
                                <CustomInput
                                  name="email"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "email",
                                      e.target.value
                                    );
                                  }}
                                />
                                {props.touched.email && props.errors.email && (
                                  <p className="text-danger">
                                    {props.errors.email}
                                  </p>
                                )}
                              </div>
                              <div className="mb-3">
                                <label className="form-label">
                                  Change Your Password
                                </label>
                                <button
                                  className="btn btn-tertiory  me-2"
                                  data-bs-toggle="modal"
                                  data-bs-target="#modal-team"
                                  type="button"
                                >
                                  Set New Password
                                </button>
                              </div>
                            </div>
                            {/* coloumn four */}
                            <div className="col ">
                              <div className="mb-3">
                                <label className="form-label">
                                  Contact Number
                                </label>
                                <CustomInput
                                  type="text"
                                  name="contactNumber"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "contactNumber",
                                      e.target.value
                                    );
                                  }}
                                />
                                {props.touched.contactNumber &&
                                  props.errors.contactNumber && (
                                    <p className="text-danger">
                                      {props.errors.contactNumber}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mb-3 mt-2">
                          <h3>Company Details</h3>
                          <hr className="mt-0" />
                          <div className="row row-cards col col-12">
                            {/* coloumn two */}
                            <div className="  col-lg-4 col-sm-12 ">
                              <div className="mb-3">
                                <label className="form-label">
                                  Company Name
                                </label>
                                <CustomInput
                                  name="companyName"
                                  onChange={(e) => {
                                    props.setFieldValue("companyName", company);
                                  }}
                                />
                                {props.touched.companyName &&
                                  props.errors.companyName && (
                                    <p className="text-danger">
                                      {props.errors.companyName}
                                    </p>
                                  )}
                              </div>

                              <div className="mb-3">
                                <label className="form-label">
                                  Company Type
                                </label>
                                <CustomSelect type="text" name="companyType">
                                  <option value="">Company Type</option>
                                  <option value="Sole Proprietorship">
                                    Sole Proprietorship
                                  </option>
                                  <option value="Partnership">
                                    Partnership
                                  </option>
                                  <option value="Limited Liability Company (LLC)">
                                    Limited Liability Company (LLC)
                                  </option>
                                  <option value="Cooperative">
                                    Cooperative
                                  </option>
                                  <option value="Public Company">
                                    Public Company
                                  </option>
                                  <option value="Private Company">
                                    Private Company
                                  </option>
                                  <option value="Corporation">
                                    Corporation
                                  </option>
                                </CustomSelect>
                                {props.touched.companyType &&
                                  props.errors.companyType && (
                                    <p className="text-danger">
                                      {props.errors.companyType}
                                    </p>
                                  )}
                              </div>
                              <div className="mb-3">
                                <label className="form-label">Industry</label>
                                <CustomInput
                                  name="industry"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "industry",
                                      e.target.value
                                    );
                                  }}
                                />
                                {props.touched.industry &&
                                  props.errors.industry && (
                                    <p className="text-danger">
                                      {props.errors.industry}
                                    </p>
                                  )}
                              </div>
                              <div className="mb-3">
                                <label className="form-label">
                                  About Company{" "}
                                </label>
                                <CustomTextArea
                                  as="textarea"
                                  type="text"
                                  style={{
                                    maxHeight: "content",
                                  }}
                                  name="aboutCompany"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "aboutCompany",
                                      e.target.value
                                    );
                                  }}
                                />
                                {props.touched.aboutCompany &&
                                  props.errors.aboutCompany && (
                                    <p className="text-danger">
                                      {props.errors.aboutCompany}
                                    </p>
                                  )}
                              </div>
                            </div>
                            {/* coloumn three */}
                            <div
                              className="
                             col-lg-4 col-sm-12"
                            >
                              <div className="mb-3">
                                <label className="form-label">Website</label>
                                <CustomInput
                                  type="text"
                                  name="website"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "website",
                                      e.target.value
                                    );
                                  }}
                                />
                                {props.touched.website &&
                                  props.errors.website && (
                                    <p className="text-danger">
                                      {props.errors.website}
                                    </p>
                                  )}
                              </div>
                              <div className="mb-3">
                                <label className="form-label">
                                  Company Headquarter{" "}
                                </label>
                                <CustomInput
                                  name="headquarter"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "headquarter",
                                      e.target.value
                                    );
                                  }}
                                />
                                {props.touched.headquarter &&
                                  props.errors.headquarter && (
                                    <p className="text-danger">
                                      {props.errors.headquarter}
                                    </p>
                                  )}
                              </div>
                              <div className="mb-3">
                                <label className="form-label">
                                  Industry Name
                                </label>
                                <CustomInput
                                  name="industryName"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "industryName",
                                      e.target.value
                                    );
                                  }}
                                />
                                {props.touched.industryName &&
                                  props.errors.industryName && (
                                    <p className="text-danger">
                                      {props.errors.industryName}
                                    </p>
                                  )}
                              </div>

                              <div className="mb-3">
                                <label className="form-label">
                                  Founded Year
                                </label>
                                <CustomInput
                                  name="foundedYear"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "foundedYear",
                                      e.target.value
                                    );
                                  }}
                                />
                                {props.touched.foundedYear &&
                                  props.errors.foundedYear && (
                                    <p className="text-danger">
                                      {props.errors.foundedYear}
                                    </p>
                                  )}
                              </div>
                            </div>
                            {/* coloumn Four */}
                            <div className="col-lg-4 col-sm-12">
                              <div className="mb-3">
                                <label className="form-label">Address</label>
                                <CustomTextArea
                                  type="text"
                                  name="companyAddress"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "companyAddress",
                                      e.target.value
                                    );
                                  }}
                                />
                                {props.touched.companyAddress &&
                                  props.errors.companyAddress && (
                                    <p className="text-danger">
                                      {props.errors.companyAddress}
                                    </p>
                                  )}
                              </div>
                              <div className="mb-3">
                                <label className="form-label">Team Size</label>
                                <CustomSelect type="text" name="teamSize">
                                  <option value="">Team Size</option>
                                  <option value="0-50">0-50</option>
                                  <option value="51-100">51-100</option>
                                  <option value="101-150">101-150</option>
                                  <option value="151-250">151-250</option>
                                  <option value="251-500">251-500</option>
                                  <option value="501-1000">501-1000</option>
                                  <option value="1000">Above 1000</option>
                                </CustomSelect>
                                {props.touched.teamSize &&
                                  props.errors.teamSize && (
                                    <p className="text-danger">
                                      {props.errors.teamSize}
                                    </p>
                                  )}
                              </div>
                              <div className="mb-3">
                                <label className="form-label">
                                  Sector Name
                                </label>
                                <CustomInput
                                  name="sectorName"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "sectorName",
                                      e.target.value
                                    );
                                  }}
                                />
                                {props.touched.sectorName &&
                                  props.errors.sectorName && (
                                    <p className="text-danger">
                                      {props.errors.sectorName}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row row-cards col-12">
                          <div className="mb-3 p-0 col-lg-4 col-md-5   border border-1 rounded-2">
                            <div className=" border  h-6 border-bottom-1 bg-primary-lt">
                              <h3 className="p-2">Your Credits Count</h3>
                            </div>

                            <div className="p-3 h-8">
                              <li>
                                Available Credits:{" "}
                                {edata?.creditsystemmodels?.[0]?.credit ?? 0}{" "}
                                Credits
                              </li>
                            </div>
                            <div className="text-end">
                              <span
                                className="btn btn-secondary m-2 "
                                data-bs-toggle="modal"
                                data-bs-target="#modal-team2"
                              >
                                Buy Now
                              </span>
                            </div>
                          </div>
                          <div
                            className="mb-3 col-lg-7 ms-lg-2 ms-md-3  col-md-6 
                           p-0 border border-1 rounded-2"
                          >
                            <div className=" border h-6 border-bottom-1 bg-primary-lt">
                              <h3 className="p-2">Your Transactions</h3>
                            </div>

                            <div
                              className="p-3"
                              style={{ overflowY: "auto", height: "12rem" }}
                            >
                              {transactions.length > 0 ? (
                                transactions?.map((el, index) => {
                                  return (
                                    <div key={index} className="">
                                      {el.paymentStatus === "Completed" ? (
                                        <div className="border rounded-2  bg-green-lt border  border-1 m-1 p-1">
                                          <div>
                                            <b> Date: </b>
                                            <span>
                                              {" "}
                                              {el?.paymentDate
                                                ? extractDate(el?.paymentDate)
                                                : ""}
                                            </span>
                                          </div>
                                          <div>
                                            {" "}
                                            <b>PaymentId:</b> {el.paymentId}{" "}
                                          </div>
                                          <div>
                                            {" "}
                                            <b>Status:</b> {el.paymentStatus}{" "}
                                          </div>
                                          <b>
                                            CreditAdded:{" "}
                                            {el?.creditAdditionCount}{" "}
                                          </b>
                                        </div>
                                      ) : el.paymentStatus === "Sent" ? (
                                        <div className="border rounded-2 bg-yellow-lt m-1 p-1">
                                          <div>
                                            <b> Date:</b>
                                            {el?.paymentDate
                                              ? extractDate(el?.paymentDate)
                                              : ""}{" "}
                                          </div>
                                          <div>
                                            <b>PaymentId</b> {el.paymentId}{" "}
                                          </div>
                                          <div>
                                            <b>Status</b> {el.paymentStatus}
                                          </div>
                                        </div>
                                      ) : el?.creditDeductionCount >= 1 ? (
                                        <div className="border rounded-2 bg-lime-lt m-1 p-1">
                                          <div>
                                            <b> Date:</b>
                                            {el?.createdAt
                                              ? extractDate(el?.createdAt)
                                              : ""}
                                          </div>
                                          <div>
                                            <b>JobId</b> {el?.jobId}
                                          </div>

                                          <div>
                                            <b>
                                              CreditDeducted:{" "}
                                              {el?.creditDeductionCount}{" "}
                                            </b>
                                          </div>
                                        </div>
                                      ) : (el?.creditAdditionCount && (!el?.paymentStatus) )? (
                                        <div className="border rounded-2 bg-green-lt  m-1 p-1">
                                          <div>
                                            <b> Date</b>
                                            {el?.createdAt
                                              ? extractDate(el?.createdAt)
                                              : ""}
                                          </div>
                                          <div>
                                            <b>PaymentId</b> {el?.paymentId || el?.id}{" "}
                                          </div>
                                          <div>
                                            <b>Status</b> {el?.paymentStatus || 'Added by Admin'}
                                          </div>
                                          <div>
                                            <b>credit Added</b>{" "}
                                            {el.creditAdditionCount}
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="border rounded-2 bg-red-lt  m-1 p-1">
                                          <div>
                                            <b> Date</b>
                                            {el?.paymentDate
                                              ? extractDate(el?.paymentDate)
                                              : ""}
                                          </div>
                                          <div>
                                            <b>PaymentId</b> {el?.paymentId}{" "}
                                          </div>
                                          <div>
                                            <b>Status</b> {el?.paymentStatus}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  );
                                })
                              ) : (
                                <p>No Transaction History</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer text-end">
                        <button type="submit" className="btn btn-primary">
                          Save
                        </button>
                      </div>
                    </React.Fragment>
                  </Form>
                )}
              </Formik>


              

              {/* buy Now modal */}
              <div
                className="modal modal-blur fade"
                id="modal-team2"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title"
                        data-bs-toggle="modal"
                        data-bs-target="#modal-team2"
                        // id="openScheduleModal"
                      >
                        Add Credits To Your Account
                      </h5>
                      <button
                        id={"closeButton"}
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    {
                      <Formik
                        initialValues={{
                          fullName: edata?.fullName,
                          contactNumber: edata?.contactNumber,
                          email: edata?.email,
                          tier: "4499",
                          quantity: "1",
                        }}
                        validationSchema={instamojoBulkSchema}
                        onSubmit={handleFinalPayment}
                      >
                        {(props) => (
                          <Form>
                            <div className="modal-body">
                              <div className="card-body">
                                <div className="mb-3 row">
                                  <label className="w-25 form-label col-3 required">
                                    Full Name:
                                  </label>
                                  <div className="w-100 col">
                                    <CustomInput
                                      name="fullName"
                                      disabled
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="mb-3 row">
                                  <label className="w-25 form-label col-3 required">
                                    Email:
                                  </label>
                                  <div className="w-100 col">
                                    <CustomInput
                                      name="email"
                                      disabled
                                      type="email"
                                    />
                                  </div>
                                </div>
                                <div className="mb-3 row">
                                  <label className="w-25 form-label col-3 required">
                                    Contact Number:
                                  </label>
                                  <div className="w-100 col">
                                    <CustomInput
                                      name="contactNumber"
                                      disabled
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="mb-3 row">
                                  <label className="form-label w-25 col-3 required">
                                    Current Plan
                                  </label>

                                  <div className="w-100 col">
                                    <CustomInput
                                      type="text"
                                      name="tier"
                                      disabled
                                    ></CustomInput>
                                  </div>
                                </div>
                                <div className="mb-3 row">
                                  <label className="w-25 form-label col-3 required">
                                    Quantity:
                                  </label>
                                  <div className="w-100 col">
                                    <CustomInput name="quantity" type="text" />
                                    {props.touched.quantity &&
                                      props.errors.quantity && (
                                        <p className="text-danger">
                                          {props.errors.quantity}
                                        </p>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn me-auto"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              {payButton ? (
                                buttonLoader ? (
                                  <Buttonloader className="btn btn-primary" />
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn btn-primary me-2"
                                    onClick={() =>
                                      handleFinalPayment(props.values)
                                    }
                                  >
                                    Pay Now
                                  </button>
                                )
                              ) : buttonLoader ? (
                                <Buttonloader className="btn btn-primary" />
                              ) : (
                                <span
                                  className="btn btn-primary me-2"
                                  onClick={handleConfirmPayment}
                                >
                                  Confirm Details
                                </span>
                              )}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    }
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Change Password modal */}

          <div
            className="modal modal-blur fade"
            id="modal-team"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Change Your Password</h5>
                  <button
                    type="button"
                    id={"btn-close"}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <Formik
                  initialValues={{
                    oldPassword: "",
                    password: "",
                    confirmPassword: "",
                  }}
                  validationSchema={changePasswordSchema}
                  onSubmit={handelSubmit}
                >
                  {(props) => (
                    <Form>
                      <div className="card-body p-4">
                        <p className="text-muted m-1">
                          Please enter and confirm your new password.
                        </p>
                        <div className="mb-3 m-1">
                          <label className="form-label">Old Password</label>
                          <div className="input-group input-group-flat">
                            <CustomInput
                              name="oldPassword"
                              autoComplete="off"
                              placeholder="Enter old password"
                              type={showOldPassword ? "text" : "password"}
                            />

                            <span className="input-group-text">
                              <span
                                onClick={() =>
                                  setShowOldPassword(!showOldPassword)
                                }
                                className="link-secondary"
                                title="Show password"
                                role="button"
                                data-bs-toggle="tooltip"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  strokeWidth="2"
                                  stroke="currentColor"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                  <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                                </svg>
                              </span>
                            </span>
                          </div>
                          {props.touched.oldPassword &&
                            props.errors.oldPassword && (
                              <p className="text-danger">
                                {props.errors.oldPassword}
                              </p>
                            )}
                        </div>
                        <div className="mb-3 m-1">
                          <label className="form-label">New Password</label>
                          <div className="input-group input-group-flat">
                            <CustomInput
                              name="password"
                              autoComplete="off"
                              placeholder="Enter password"
                              type={showPass ? "text" : "password"}
                            />

                            <span className="input-group-text">
                              <span
                                onClick={() => setShowPass(!showPass)}
                                className="link-secondary"
                                title="Show password"
                                role="button"
                                data-bs-toggle="tooltip"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  strokeWidth="2"
                                  stroke="currentColor"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                  <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                                </svg>
                              </span>
                            </span>
                          </div>
                          {props.touched.password && props.errors.password && (
                            <p className="text-danger">
                              {props.errors.password}
                            </p>
                          )}
                        </div>
                        <div className=" m-1">
                          <label className="form-label">
                            Confirm New Password
                          </label>
                          <div className="input-group input-group-flat">
                            <CustomInput
                              name="confirmPassword"
                              placeholder="Confirm password"
                              autoComplete="off"
                              type={showConfirmPass ? "text" : "password"}
                            />

                            <span className="input-group-text">
                              <span
                                onClick={() =>
                                  setShowConfirmPass(!showConfirmPass)
                                }
                                className="link-secondary"
                                title="Show password"
                                role="button"
                                data-bs-toggle="tooltip"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  strokeWidth="2"
                                  stroke="currentColor"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                  <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                                </svg>
                              </span>
                            </span>
                          </div>
                          {props.touched.confirmPassword &&
                            props.errors.confirmPassword && (
                              <p className="text-danger">
                                {props.errors.confirmPassword}
                              </p>
                            )}
                        </div>

                        <div className="form-footer text-end">
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                          >
                            submit
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>

          {/* Add credit Modal */}
          <div
            className="modal modal-blur fade"
            id="modal-credit"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Add Credit</h5>
                  <button
                    type="button"
                    id={"btn-close-credit"}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <Formik
                  initialValues={{
                    companyName: "",
                    companyId: "",
                    numberOfCredit: "",
                    availableCredits:""
                  }}
                  validationSchema={addAdminCreditSchema}
                  onSubmit={handleAddCredit}
                >
                  {(props) => (
                    <Form>
                      <div className="card-body p-4">
                        <p className="text-muted m-1">
                          Please add Company details
                        </p>

                        <div className="mb-3 m-1">
                          <label className="form-label">Company Id</label>
                          <div className="input-group input-group-flat">
                            <CustomInput
                              name="companyId"
                              autoComplete="off"
                              placeholder="Enter company id"
                              onChange={(e) => {
                                const value = e.target.value;
                                setInputLoader(true)
                                props.setFieldValue("companyId", value);

                                // Clear the previous timer
                                if (timer) {
                                  clearTimeout(timer);
                                }

                                // Set a new timer
                                if (value) {
                                  const newTimer = setTimeout(() => {
                                    handleCompanyChange(value, props); // Call your request function here
                                  }, 2000); // Delay of 2 seconds
                                  setTimer(newTimer);
                                }
                              }}
                            />
                          </div>
                          {props.touched.companyId &&
                            props.errors.companyId && (
                              <p className="text-danger">
                                {props.errors.companyId}
                              </p>
                            )}
                        </div>
                        <div className="mb-3 m-1">
                          <label className="form-label">Company Name</label>
                          <div className="input-group input-group-flat">
                            <CustomInput
                              name="companyName"
                              autoComplete="off"
                              placeholder="Enter company"
                            />
                          {inputLoader? (
                            <Buttonloader className='btn btn-primary' />
                          ): ''}
                          </div>
                          {props.touched.companyName &&
                            props.errors.companyName && (
                              <p className="text-danger">
                                {props.errors.companyName}
                              </p>
                            )}
                        </div>
                        <div className="mb-3 m-1">
                          <label className="form-label">Available Credits</label>
                          <div className="input-group input-group-flat">
                            <CustomInput
                              disabled
                              name="availableCredits"
                              autoComplete="off"
                              placeholder=''
                            />
                          {inputLoader? (
                            <Buttonloader className='btn btn-primary' />
                          ): ''}
                          </div>
                          {props.touched.availableCredits &&
                            props.errors.availableCredits && (
                              <p className="text-danger">
                                {props.errors.availableCredits}
                              </p>
                            )}
                        </div>

                        <div className=" m-1">
                          <label className="form-label">Number of Credit</label>
                          <div className="input-group input-group-flat">
                            <CustomInput
                              name="numberOfCredit"
                              placeholder="Add number of credits"
                              autoComplete="off"
                            />
                            <span className="btn btn-secondary">₹{+props?.values?.numberOfCredit*4499}</span>
                          </div>
                          {props.touched.numberOfCredit &&
                            props.errors.numberOfCredit && (
                              <p className="text-danger">
                                {props.errors.numberOfCredit}
                              </p>
                            )}
                        </div>

                        <div className="form-footer text-end">
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                          >
                            submit
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>

          {/* Generate QR Modal */}

          <div
            className="modal modal-blur fade"
            id="modal-team-qr"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Generate QR Code</h5>
                  <button
                    type="button"
                    id={"btn-close-qr"}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <Formik
                  initialValues={{qrUrl:'', purpose:''}}
                  onSubmit={handelGenerateQr}
                >
                  {(props) => (
                    <Form>
                      <div className="card-body p-4">
                        <p className="text-muted m-1 mb-1">
                          Please enter url for qr generation.
                        </p>
                        <div className="mb-3 m-1 mt-2">
                          <label htmlFor="">Url</label>
                          <div className="input-group input-group-flat">
                            <CustomInput
                              name="qrUrl"
                              autoComplete="off"
                              type='url'
                              placeholder="Enter Url"
                              onChange={(e) => props.setFieldValue('qrUrl', e.target.value)}
                              
                            />
                          </div>
                          {props.touched.qrUrl &&
                            props.errors.qrUrl && (
                              <p className="text-danger">
                                {props.errors.qrUrl}
                              </p>
                            )}
                        </div>

                        <div className="mb-3 m-1 mt-2">
                          <label htmlFor="">Purpose</label>
                          <div className="input-group input-group-flat">
                            <CustomInput
                              name="purpose"
                              autoComplete="off"
                              maxLength='30'
                              placeholder="Enter purpose"
                              onChange={(e) => props.setFieldValue('purpose', e.target.value)}
                              
                            />
                          </div>
                          {props.touched.purpose &&
                            props.errors.purpose && (
                              <p className="text-danger">
                                {props.errors.purpose}
                              </p>
                            )}
                        </div>
                   
                     

                        <div className="form-footer text-end">
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                          >
                            submit
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Myprofile;

