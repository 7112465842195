import * as yup from "yup";
export const EmailmarketingSchema = yup.object().shape({
  leaveDate: yup.date(),
  leaveType: yup.string(),
  leaveReason: yup.string(),
  leaveStatus: yup.string(),
  leaveStartDate: yup.date(),
  leaveEndDate: yup.date(),
  leaveReasonType: yup.string(),
});

export const createTemplateSchema = yup.object().shape({
    templateName:yup.string().required(),
    subjectPart:yup.string().required()
    
})