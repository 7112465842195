import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import {
  createTemplateSchema,
  EmailmarketingSchema,
} from "../../../Schemas/Email Marketing/email_marketing_schema";
import { emailMarketingInfo } from "../../../Schema Info/emailmarketinginfo";
import performRequest from "../../../APIs/request";
import { Toast } from "../../CustomComponent/toast";
import { useNavigate } from "react-router";
import LoadingPage from "../../../Components/layouts/loading";
const Emailmarketing = () => {
  const navigate = useNavigate();
  const [templateData, setTemplateData] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    setLoading(true)
    performRequest("GET", `bulkemail/get-all-email-template/`)
    .then((res) => {
      // console.log(res, "ths");
      if (res.status === 200) {
        setTemplateData(res.data)
      }
    })
    .catch((err) => {
      console.error(err);
    }).finally(() => setLoading(false));

  }, []);

if(loading){
  return   <LoadingPage />
}



  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-body">
            <div className="row col-12">
              <div className="card-header">
                <h1>Email Marketing</h1>
              </div>
              <div className="card">
               
                <div className="card-body">
                <div className="card-title border border-1 border-grey d-flex justify-content-between"><h2>Templates</h2>
                <div className="text-end">
                   <button
                      className="btn btn-primary"
                      onClick={() => navigate("/create-email-template")}
                    >
                      Create Email Template
                    </button>
                   </div>
                
               
          
                </div>
            
                <div class="row row-cards">
                  <div class="col-12">
                    <div class="card" >
                      <div class="card-body card-body-scrollable card-body-scrollable-shadow">
                        <div class="divide-y">
                          <div>
                           {templateData && (templateData.length>0) && (
                              templateData.map((el,index)=>{
                            
                                return(
                                 
                                  <div class="row mb-1" key={index}>
                              <div class="col-auto">
                                <span class="avatar"></span>
                              </div>
                              <div class="col">
                                <div class="text-truncate">
                                  <strong>{templateData?.[index]?.Template?.TemplateName}</strong>
                                </div>
                                <div class="text-secondary">Created : <span> {templateData?.[index]?.Timestamp}</span></div>
                              </div>
                              <div class="col-auto align-self-center">
                              <button
                          className="btn btn-secondary"
                          onClick={() => navigate(`/send-bulk-email/${templateData?.[index]?.Template?.TemplateName}`)}
                        >
                        send mail
                        </button>
                                <button
                          className="btn btn-secondary"
                          onClick={() => navigate(`/edit-email-template/${templateData?.[index]?.Template?.TemplateName}`)}
                        >
                          Edit template
                        </button>
                              
                              </div>
                            </div>
                                )
                              })
                           )}
                          </div>
                                                            
                      
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                     
                 </div>
              </div>
            </div>
       
          </div>
        </div>
      </div>

    </div>
  );
};

export default Emailmarketing;
