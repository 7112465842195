import React, { useEffect, useState } from "react";

import performRequest from "../../../APIs/request";
import { Toast } from "../../CustomComponent/toast";
import { useNavigate, useParams } from "react-router";
import { candidate, company, ITStaff } from "../../../Config/config";

const Sendbulktemplatemails = () => {
  const [templateData, setTemplateData] = useState({});
  const [candidates, setCandidates] = useState("");
  const [employers, setEmployers] = useState("");
  const [ITStaff, setITStaff] = useState("");
  const [checkBoxes, setCheckBoxes] = useState({
    candidates: false,
    employers: false,
    employees: false,
    ITStaff: false,
  });
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    performRequest("GET", `bulkemail/get-email-template/${id}`)
      .then((res) => {
        // console.log(res, "ths");
        if (res.status === 200) {
          setTemplateData(res.data.Template);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleCheckboxChange = async (event) => {
    const { name, checked } = event.target;

    // console.log(name, checked);

    // Update individual checkbox state
    const updatedCheckboxes = { ...checkBoxes, [name]: checked };
    setCheckBoxes(updatedCheckboxes);

    // Check if all checkboxes are checked
    const areAllChecked = Object.values(updatedCheckboxes).every(
      (isChecked) => isChecked
    );
  

    // Function to fetch user email data
    async function getUserEmailData(userRole) {
      try {
        const res = await performRequest(
          "GET",
          `/bulkemail/get-user-by-userrole/${userRole}`
        );
        if (res.status === 200) {
         
          if (userRole === candidate) {
            setCandidates(res.data);
          } else if(userRole === company){ setEmployers(res.data)}
          
          else if(userRole === ITStaff){
              setITStaff(res.data)
          };
        }
      } catch (err) {
        console.error("Error fetching user email data:", err);
      }
    }

    // Handle individual checkbox actions
    if (updatedCheckboxes.candidates && !candidates) {
      await getUserEmailData(candidate);
    }

    if (updatedCheckboxes.employers && !employers) {
      getUserEmailData(company); // Fetch employers email
    }

    if (updatedCheckboxes.ITStaff && !ITStaff) {
      const data = await getUserEmailData(ITStaff); // Fetch ITStaff email
      setITStaff(data);
      
    }
  };

  const handleSendBulkMail = (event) => {
    event.preventDefault();

    let payload = [];

    if (checkBoxes.candidates && candidates) {
      payload = [...payload, ...candidates];
    }
    if (checkBoxes.employers && employers) {
      payload = [...payload, ...employers];
    }
    if (checkBoxes.ITStaff && ITStaff) {
      payload = [...payload, ...ITStaff];
    }
    
    let requestData = { payload, TemplateName: id };

    if (payload && payload.length > 0) {
      performRequest(
        "POST",
        `/bulkemail/send-bulk-template-email/`,
        requestData
      )
        .then((res) => {
         
          if (res.status === 200) {
            Toast("success", res.data.message);
          }
        })
        .catch((err) => {
          console.error(err);
          Toast("error", err.response.data.error.message);
        });
    }
  };

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-body">
            <div className="card-header">
              <h1>Send Bulk Emails</h1>
            </div>
            <div className="row row-cards col-12">
              <div className="col-4">
                <form class="card">
                  <div class="card-header">
                    <h3 class="card-title">Choose the list Below</h3>
                  </div>
                  <div class="card-body">
                    <div class="mb-3">
                      <div>
                        <label class="form-check">
                          <input
                            class="form-check-input"
                            name="candidates"
                            checked={checkBoxes.candidates}
                            onChange={handleCheckboxChange}
                            type="checkbox"
                          />
                          <span class="form-check-label">Candidates</span>
                        </label>
                        <label class="form-check">
                          <input
                            class="form-check-input"
                            name="employers"
                            checked={checkBoxes.employers}
                            onChange={handleCheckboxChange}
                            type="checkbox"
                          />
                          <span class="form-check-label">Employers</span>
                        </label>
                        {/* <label class="form-check">
                          <input
                            class="form-check-input"
                            name="employees"
                            checked={checkBoxes.employees}
                            onChange={handleCheckboxChange}
                            type="checkbox"
                            disabled=""
                          />
                          <span class="form-check-label">Employees</span>
                        </label> */}
                        <label class="form-check">
                          <input
                            class="form-check-input"
                            name="ITStaff"
                            checked={checkBoxes.ITStaff}
                            onChange={handleCheckboxChange}
                            type="checkbox"
                            disabled=""
                          />
                          <span class="form-check-label">IT Staff</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button
                      type="submit"
                      onClick={handleSendBulkMail}
                      class="btn btn-primary"
                    >
                      Send Bulk Emails
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-8">
                <div className="card">
                  <div className="card-body">
                    {templateData && (
                      <div>
                        <h1> Subject:- {templateData.SubjectPart}</h1>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: templateData?.HtmlPart,
                          }}
                        />
                        <div className="footer text-end">
                          <button
                            className="btn btn-primary"
                            onClick={() =>
                              navigate(`/edit-email-template/${id}`)
                            }
                          >
                            Edit Template
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sendbulktemplatemails;
