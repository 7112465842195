import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/footer";
import { useNavigate } from "react-router";
import CustomInput from "../CustomComponent/custom_input";
import { useSelector } from "react-redux";
import { instamojoInfo } from "../../Schema Info/instamojoInfo";
import { instamojoSchema } from "../../Schemas/instamojo_schema";
import performRequest from "../../APIs/request";
import { Form, Formik } from "formik";
import { Toast } from "../CustomComponent/toast";
import Buttonloader from "../../Components/layouts/button_loader";
import CustomSelect from "../CustomComponent/custom_select";
import LoadingPage from "../../Components/layouts/loading";

const Payment = () => {
  const navigate = useNavigate();

  const store = useSelector((store) => store?.userLogin);
  const [loading, setLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [payButton, setPayButton] = useState(false);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(instamojoInfo);
  const handleConfirmPayment = (e) => {

    let isUser = store?.user?.UserId;
    if (!isUser) {
      return Toast("error", "Please Login");
    }
    setButtonLoader(true);
    let data = { userId: store?.user.UserId };
    performRequest("POST", "/payment/generatetoken", data)
      .then((res) => {
        if (res.status === 201) {
          setToken(res.data);
          setButtonLoader(false);
          setPayButton(true);
        } 
      })
      .catch((err) => {
        console.error("Error", err);
         if(err.response.status === 401){
          Toast('error', err.response.data.flag)
         }else{
          Toast('error', err.response.statusText)
         }
         setButtonLoader(false);
      });
  };
  const handleFinalPayment = (values) => {
    let isUser = store?.user?.UserId;
    if (!isUser) {
      return Toast("error", "Please Login");
    }

    let data = {
      ...values,
      price: parseInt(values.tier),
      userId: store?.user.UserId,
      token: token,
    };
    setButtonLoader(true)
    performRequest("POST", "/payment/create-payment", data)
      .then((res) => {
        if (res.status === 201) {
          setButtonLoader(false)
          window.location.href = res.data.longurl;
        
        }
      })
      .catch((err) => {
        console.error("Error", err);
        if (err.response.status === 401) {
          Toast("error", err.response.data.flag);
        }else if(err.response.status === 400){
            Toast("error", err.response.data.flag )
        } else {
          Toast("error", err.response.statusText);
        }
         setButtonLoader(false)
      });
  };

  useEffect(() => {
    const handleGetUserInfo = () => {
      performRequest("GET", `/user/userbyid/${store?.user?.UserId}`)
        .then((res) => {
          if (res.status === 200) {
            setUser({
              fullName: res.data.fullName,
              contactNumber: res.data.contactNumber,
              email: res.data.email,
              tier: 4499,
              quantity:""
            });
          } else {
            Toast("error", "Server Error");
          }
        })
        .catch((err) => {
          console.error("Error", err);
        });
    };
   if(store?.user?.UserId){
    handleGetUserInfo()
   }
  }, []);

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-body">
            <div className="section-header">
              <h2 className="section-title section-title-lg m-0 p-0">
                PAYMENT DETAILS
              </h2>
              <p className="section-description mb-4 p-0">
                To complete your purchase, please enter your payment information
                below. Ensure all fields are filled out accurately to avoid any
                delays in processing your order.
              </p>
            </div>

            <div className="card card-md">
              <div className="card-header">
                <h1 className="card-title">Payment Details</h1>
              </div>
              <div className="card-body">
                {Object.values(user).some((value) => value !== "") && (
                  <Formik
                    initialValues={user}
                    validationSchema={instamojoSchema}
                    onSubmit={handleFinalPayment}
                  >
                    {(props) => (
                      <Form>
                        <div className="card-body p-4">
                          <div className="mb-3">
                            <label className="form-label">Full Name</label>
                            <div className="input-group input-group-flat">
                              <CustomInput
                                name="fullName"
                                autoComplete="off"
                                placeholder="Enter your full name"
                                type="text"
                                disabled
                              />
                            </div>
                            {props.touched.fullName &&
                              props.errors.fullName && (
                                <p className="text-danger">
                                  {props.errors.fullName}
                                </p>
                              )}
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <div className="input-group input-group-flat">
                              <CustomInput
                                name="email"
                                autoComplete="off"
                                placeholder="Enter email"
                                type="email"
                                disabled
                              />
                            </div>
                            {props.touched.email && props.errors.email && (
                              <p className="text-danger">
                                {props.errors.email}
                              </p>
                            )}
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Contact Number</label>
                            <div className="input-group input-group-flat">
                              <CustomInput
                                name="contactNumber"
                                placeholder="Enter your contact number"
                                autoComplete="off"
                                type="text"
                                disabled
                              />
                            </div>
                            {props.touched.contactNumber &&
                              props.errors.contactNumber && (
                                <p className="text-danger">
                                  {props.errors.contactNumber}
                                </p>
                              )}
                          </div>
                          
                          <div className="mb-3">
                            <label className="form-label">Select Plan</label>
                              <div>
                                
                              <CustomInput type="text" name="tier" disabled>
                                                        
                                                        </CustomInput>
                              </div>
                            {props.touched.tier && props.errors.tier && (
                              <p className="text-danger">{props.errors.tier}</p>
                            )}
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Quantity</label>
                            <div className="input-group input-group-flat">
                              <CustomInput
                                name="quantity"
                                autoComplete="off"
                                placeholder="Enter credit quantity"
                                type="text"
                              />
                            </div>
                            {props.touched.quantity &&
                              props.errors.quantity && (
                                <p className="text-danger">
                                  {props.errors.quantity}
                                </p>
                              )}
                          </div>

                          <div className="form-footer text-end">
                            {payButton ? (
                          buttonLoader  ? <Buttonloader className="btn btn-primary" /> :   <button
                          type="submit"
                          className="btn btn-primary me-2"
                          onClick={() =>
                            handleFinalPayment
                          }
                        >
                          Pay Now
                        </button>
                            ) : buttonLoader ? (
                              <Buttonloader className="btn btn-primary" />
                            ) : (
                              <span
                                className="btn btn-primary me-2"
                                onClick={handleConfirmPayment}
                              >
                                Confirm Details
                              </span>
                            )}
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Payment;
