import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/footer";
// import StepRoutes from "../../Routes/steps_routes";
import performRequest from "../../APIs/request";
import { Toast } from "../CustomComponent/toast";
import LoadingPage from "../../Components/layouts/loading";
import getFilteredURL from "../../Components/Functions/filters.getUrl";
import DynamicFilterInput from "../../Components/layouts/filter.inpit";
import Pagination from "../../Components/layouts/pagination";
import Attendancechart from "../Dashboard/Dashboard Components/attendance_chart";
import { useSelector } from "react-redux";
import { CustomErrorResponse } from "../CustomComponent/custom_error_response";
import { FcClock } from "react-icons/fc";
import Attendancetracking from "../Dashboard/Dashboard Components/attendance_tracking";
import Attendancetable from "./Employee Attandance/attendence_table";
import { superAdmin } from "../../Config/config";

const Employeeattendance = () => {
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [showPage, setShowPage] = useState(1);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({
    startdate: "",
    enddate: "",
    name: "",
  });

  const store = useSelector((store) => store.userLogin.user);
  const [employeeName, setEmployeeName] = useState(
    (store && store.Role == process.env.REACT_APP_ROLE_ADMIN_S) ||
      store.Role == process.env.REACT_APP_ROLE_ADMIN
      ? "All"
      : store.Name
  );

  const [attendanceData, setAttendanceData] = useState();
  const [employeeData, setEmployeeData] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [attendanceFrom, setAttendanceFrom] = useState(7);

  const years = [2024, 2025, 2026, 2027, 2028, 2029, 2030];

  useEffect(() => {

    if (employeeName) {
      performRequest("GET", `/attendance/getAllAttendance?name=${employeeName}`)
        .then((res) => {
          if (res.status === 200) {
            setAttendanceData(res.data);
          } else {
            Toast("error", "Error while getting Attendance!");
          }
        })
        .catch((err) => {
          // Toast("error", "Server Error!");
          // console.error(err);
          CustomErrorResponse(err);
        })
        .finally(() => setLoading(false));
    }
  }, [employeeName]);

  useEffect(() => {
    setLoading(true);
    performRequest(
      "GET",
      getFilteredURL("/attendance/getAttendance", page, filters)
    )
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setTotalPages(Math.ceil(res.data.AttendanceCount / 50));
        } else {
          Toast("error", "Error while getting Attendance!");
        }
      })
      .catch((err) => {
        // Toast("error", "Server Error!");
        // console.error(err);
        CustomErrorResponse(err);
      })
      .finally(() => setLoading(false));
  }, [refresh, page]);

  const ClassNameReturnAttendance = () => {
    let className = "col-12 p-4";
    if (
      store.Role == process.env.REACT_APP_ROLE_ADMIN ||
      store.Role == process.env.REACT_APP_ROLE_ADMIN_S
    ) {
      className = "col-12 p-4";
    }
    return className;
  };

  const months = [
    { name: "January", value: 0 },
    { name: "February", value: 1 },
    { name: "March", value: 2 },
    { name: "April", value: 3 },
    { name: "May", value: 4 },
    { name: "June", value: 5 },
    { name: "July", value: 6 },
    { name: "August", value: 7 },
    { name: "September", value: 8 },
    { name: "October", value: 9 },
    { name: "November", value: 10 },
    { name: "December", value: 11 },
  ];

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());




  useEffect(() => {
    function  getCompanyEmployeeNames(){
        performRequest("GET", "/tasks/companyemployees/")
        .then((res) => setEmployeeData(res?.data))
        .catch((err) => CustomErrorResponse(err));
      }
      if(store?.Role==='superAdmin'){
        getCompanyEmployeeNames()
      }
    }, []);


  useEffect(() => {
    if (employeeName == "All") {
      performRequest("GET", `/attendance/getAllAttendance`)
        .then((res) => {
          if (res.status === 200) {
            setAttendanceData(res.data);
          } else {
            Toast("error", "Error while getting Attendance!");
          }
        })
        .catch((err) => {
          // Toast("error", "Server Error!");
          // console.error(err);
          CustomErrorResponse(err);
        })
        .finally(() => setLoading(false));
    } else {
      performRequest("GET", `/attendance/getAllAttendance?name=${employeeName}`)
        .then((res) => {
          if (res.status === 200) {
            setAttendanceData(res.data);
          } else {
            Toast("error", "Error while getting Attendance!");
          }
        })
        .catch((err) => {

          CustomErrorResponse(err);
        })
        .finally(() => setLoading(false));
    }
  }, [employeeName]);

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="col-md-8">
                <h2 className="page-title mb-1">
                  <FcClock className="fs-2  me-1" /> Employee Attendance
                  Management
                </h2>
                <h4 className="text-muted">
                  {/* <StepRoutes /> */} Calculate & check employee attendance
                </h4>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-12 py-2">
              <div className="card p-2">
                <div
                  id="faq-1"
                  className="accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div>

                    <div className="accordion-header m-0" role="tab">
                      <button
                        className="accordion-button fs-3 d-md-none p-0"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-1-1"
                      >
                        <h3 className="m-1">Filter result</h3>
                      </button>
                    </div>

                    <div
                      id="faq-1-1"
                      className="accordion-collapse collapse d-md-none"
                      role="tabpanel"
                      data-bs-parent="#faq-1"
                    >
                      <div className="accordion-body pt-0">
                        <div className="row">
                          <div className="col-12">
                            <DynamicFilterInput
                              label="Start Date:"
                              value={filters.startdate}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  startdate: e.target.value,
                                })
                              }
                              type="date"
                            />
                          </div>
                          <div className="col-12">
                            <DynamicFilterInput
                              label="End Date:"
                              value={filters.enddate}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  enddate: e.target.value,
                                })
                              }
                              type="date"
                            />
                          </div>
                          <div className="col-12">
                            <DynamicFilterInput
                              label="Search name:"
                              value={filters.name}
                              onChange={(e) =>
                                setFilters({ ...filters, name: e.target.value })
                              }
                              placeholder="search name..."
                            />
                          </div>

                          <div className="col-12">
                            <div className="row mt-2">
                              <div className="col-6">
                                <button
                                  onClick={() => setRefresh(!refresh)}
                                  className="btn btn-success w-100"
                                >
                                  Apply Filter
                                </button>
                              </div>
                              <div className="col-6">
                                <button
                                  onClick={() => {
                                    setFilters({
                                      startdate: "",
                                      enddate: "",
                                      leadname: "",
                                      vendor: "",
                                    });
                                    setRefresh(!refresh);
                                  }}
                                  className="btn btn-secondary w-100"
                                >
                                  Reset Filter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-body d-none d-md-block">
                      <div className="row">
                        <h3>Filter result</h3>
                        <div className="col-12">
                          <DynamicFilterInput
                            label="Start Date:"
                            value={filters.startdate}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                startdate: e.target.value,
                              })
                            }
                            type="date"
                          />
                        </div>
                        <div className="col-12">
                          <DynamicFilterInput
                            label="End Date:"
                            value={filters.enddate}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                enddate: e.target.value,
                              })
                            }
                            type="date"
                          />
                        </div>
                        <div className="col-12">
                          <DynamicFilterInput
                            label="Search name:"
                            value={filters.name}
                            onChange={(e) =>
                              setFilters({ ...filters, name: e.target.value })
                            }
                            placeholder="search name..."
                          />
                        </div>

                        <div className="col-12">
                          <div className="row mt-2">
                            <div className="col-6">
                              <button
                                onClick={() => setRefresh(!refresh)}
                                className="btn btn-success w-100"
                              >
                                Apply Filter
                              </button>
                            </div>
                            <div className="col-6">
                              <button
                                onClick={() => {
                                  setFilters({
                                    startdate: "",
                                    enddate: "",
                                    leadname: "",
                                    vendor: "",
                                  });
                                  setRefresh(!refresh);
                                }}
                                className="btn btn-secondary w-100"
                              >
                                Reset Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9 col-sm-12 py-2">
              <div className="card">
                {store.Role !== superAdmin && (
                  <div className={ClassNameReturnAttendance()}>
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Attendance summary for users</h3>
                        <div className="ms-auto">
                          <div className="d-flex">
                            {(store.Role === 'superAdmin' ||
                              store.Role ===
                                'superAdmin') && (
                              <div className="me-2">
                                <div className="dropdown">
                                  <a
                                    className="dropdown-toggle text-muted"
                                    href="/"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    {employeeName}
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <span
                                      onClick={() => setEmployeeName("All")}
                                      className="dropdown-item"
                                    >
                                      All
                                    </span>
                                    {employeeData &&
                                      employeeData.map((data, index) => (
                                        <span
                                          key={index}
                                          onClick={() =>
                                            setEmployeeName(data?.fullName)
                                          }
                                          className="dropdown-item"
                                        >
                                          {data?.fullName}
                                        </span>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="dropdown">
                              <a
                                className="dropdown-toggle text-muted"
                                href="/"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                {months[selectedMonth].name}
                              </a>
                              <div className="dropdown-menu dropdown-menu-end">
                                {months.map((month) => (
                                  <span
                                    key={month.value}
                                    onClick={() =>
                                      setSelectedMonth(month.value)
                                    }
                                    className="dropdown-item"
                                  >
                                    {month.name}
                                  </span>
                                ))}
                              </div>
                            </div>
                            <div className="dropdown ms-2 me-2">
                              <a
                                className="dropdown-toggle text-muted"
                                href="/"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                {selectedYear}
                              </a>
                              <div className="dropdown-menu dropdown-menu-end">
                                {years.map((year) => (
                                  <span
                                    key={year}
                                    onClick={() => setSelectedYear(year)}
                                    className="dropdown-item"
                                  >
                                    {year}
                                  </span>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {employeeData && attendanceData && (
                        <Attendancetracking
                          employeeData={employeeData}
                          attendanceData={attendanceData?.Attendance}
                          attendanceFrom={selectedMonth}
                          selectedYear={selectedYear}
                          employeeName={employeeName}
                        />
                        
                      )}
                    </div>
                  </div>
                )}

                {store.Role === superAdmin && store.Role !== process.env.REACT_APP_ROLE_PURCHASE &&
                  store.Role !== process.env.REACT_APP_ROLE_AGRONOMIST &&
                  store.Role !== process.env.REACT_APP_ROLE_INSPECTION && (
                    <div className="col-12 p-4">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Attendance summary</h3>
                          {store?.Role !== process.env.REACT_APP_ROLE_SALLE &&
                            store?.Role !==
                              process.env.REACT_APP_ROLE_SALESAGENCY && (
                              <div className="ms-auto me-3 lh-1">
                                <div className="dropdown">
                                  <a
                                    className="dropdown-toggle text-muted"
                                    href="/"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    {employeeName} 
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    {employeeData &&
                                      employeeData.map((data, index) => {
                                        return (
                                          <span
                                            key={index}
                                            onClick={() =>
                                              setEmployeeName(data?.fullName)
                                            }
                                            className="dropdown-item"
                                          >
                                            {data?.fullName}
                                          </span>
                                        );
                                      })}
                                  </div>
                                </div>
                              </div>
                            )}
                          <div
                            className={
                              store?.Role == process.env.REACT_APP_ROLE_SALLE ||
                              store?.Role ==
                                process.env.REACT_APP_ROLE_SALESAGENCY
                                ? "ms-auto lh-1"
                                : "lh-1"
                            }
                          >
                            <div className="dropdown">
                              <a
                                className="dropdown-toggle text-muted"
                                href="/"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                {attendanceFrom === 0
                                  ? "Today"
                                  : `Last ${attendanceFrom || 7} days`}
                              </a>
                              <div className="dropdown-menu dropdown-menu-end">
                                <span
                                  onClick={() => setAttendanceFrom(7)}
                                  className="dropdown-item"
                                >
                                  Last 7 days
                                </span>
                                <span
                                  onClick={() => setAttendanceFrom(30)}
                                  className="dropdown-item"
                                >
                                  Last 30 days
                                </span>
                                <span
                                  onClick={() => setAttendanceFrom(45)}
                                  className="dropdown-item"
                                >
                                  Last 45 days
                                </span>
                                <span
                                  onClick={() => setAttendanceFrom(60)}
                                  className="dropdown-item"
                                >
                                  Last 2 months
                                </span>
                                <span
                                  onClick={() => setAttendanceFrom(90)}
                                  className="dropdown-item"
                                >
                                  Last 3 months
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {attendanceData &&
                          attendanceData?.Attendance &&
                          attendanceFrom && (
                            <Attendancechart
                              attendanceFrom={attendanceFrom}
                              attendance={attendanceData?.Attendance}
                            />
                            
                          )}
                      </div>
                    </div>
                  )}

                {data && data?.Attendance && (
                  <Attendancetable
                    Attendance={data?.Attendance}
                    currentPage={page}
                    itemsPerPage={20}
                  />
                )}
                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  pageDatalength={data?.Attendance?.length}
                  showPage={showPage}
                  setShowPage={setShowPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Employeeattendance;
